.wardrobeModifierTypeDetails {
	.modifierPermission {
		margin-top: 1em;
	}
}

.wardrobeModifierTypeDescription {
	white-space: pre-wrap;
	overflow-wrap: break-word;
}

.wardrobeModifierTypeDescriptionContainer {
	width: min(35em, 90vw);
	min-width: 100%;
}
