@use 'common';

//#region Flexbox

.flex-row, .flex-row-nowrap {
	@include common.flex;
}

.flex-row-wrap {
	@include common.flex($wrap: wrap);
}

.flex-col, .flex-col-nowrap {
	@include common.flex($direction: column);
}

.flex-col-wrap {
	@include common.flex(column, wrap);
}

@for $weight from 1 through 12 {
	.flex-#{$weight} {
		flex: $weight;
	}
	.flex-grow-#{$weight} {
		flex-grow: $weight;
	}
	.flex-shrink-#{$weight} {
		flex-shrink: $weight;
	}
}

.flex {
	flex: 1;
}

//#endregion

//#region Positioning

.center-absolute {
	@include common.center-absolute;
}

.center-fixed {
	@include common.center-fixed;
}

.center-flex {
	@include common.center-flex;
	// Give this a default gap so things look better without custom style
	gap: common.spacing('medium');
}

.hidden {
	display: none !important;
}

.invisible {
	visibility: hidden;
}

.fill {
	width: 100%;
	height: 100%;
}

.fill-x {
	width: 100%;
}

.fill-y {
	height: 100%;
}

.fit {
	max-width: 100%;
	max-height: 100%;
}

.zero-width {
	width: 0;
}

.zero-height {
	height: 0;;
}

.margin-none {
	margin: 0;
}

//#endregion

//#region Overflow

@each $type in (visible, hidden, auto, scroll) {
	.overflow-#{$type} {
		overflow: $type;
	}
	.overflow-x-#{$type} {
		overflow-x: $type;
	}
	.overflow-y-#{$type} {
		overflow-y: $type;
	}
}

//#endregion

//#region Spacing

@each $spacing-name, $spacing-value in common.$spacing {
	.spacing-#{$spacing-name} {
		padding: $spacing-value;
		gap: $spacing-value;
	}

	.padding-#{$spacing-name} {
		padding: $spacing-value;
	}

	.gap-#{$spacing-name} {
		gap: $spacing-value;
	}
}

//#endregion

//#region Font size

.fontSize-xs {
	font-size: x-small;
}

.fontSize-s {
	font-size: small;
}

.fontSize-m {
	font-size: medium;
}

.fontSize-l {
	font-size: large;
}

.fontSize-xl {
	font-size: x-large;
}

//#endregion

//#region Text decoration

.text-strikethrough {
	text-decoration: line-through;
}

//#endregion

//#region Misc

.selectable {
	user-select: text;
}

.selectable-all {
	user-select: all;
}

.display-linebreak {
	white-space: pre-line;
}

.textoverflow-ellipsis {
	text-overflow: ellipsis;
}

.pointer-events-disable {
	pointer-events: none;
}

.pointer-events-enable {
	pointer-events: auto;
}

.warning-box, .error-box {
	@include common.center-flex;
	border: common.$theme-separator solid 1px;
	border-radius: 1em;
	padding: 0.25em 0.5em;
	margin: 0.25em;

	p {
		margin: 0.25em 0;
	}
}

.warning-box {
	background: common.$theme-warningbox-background;
	color: common.$theme-warningbox-text;
}

.error-box {
	background: common.$theme-errorbox-background;
	color: common.$theme-errorbox-text;
}

//#endregion
