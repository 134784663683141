@use '../../styles/common';

.attribute-description {
	padding: 1em;
	background: common.$theme-hoverdialog-background;
	color: common.$theme-hoverdialog-text;
	border: 2px solid common.$theme-hoverdialog-border;
	border-radius: 1em;
	line-height: 1.5em;
	text-align: center;
}

.help-image {
	&:hover {
		filter: invert(30%);
	}
}

.settings {
	display: flex;
	overflow: hidden;

	.tab-content {
		max-width: 100vw;
	}

	.settings-tab-wrapper {
		width: 100%;
		height: 100%;
		overflow: auto;

		border-top: 2px solid common.$theme-separator;
	}

	.settings-tab {
		min-width: fit-content;
		min-height: fit-content;
		display: flex;
		flex-flow: column;
		align-items: center;

		flex: 1;

		.settings-tab-contents {
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: stretch;

			min-height: fit-content;
			flex-grow: 1;

			gap: 1em;
			padding: 1em;

			fieldset {
				display: flex;
				flex-direction: column;
				gap: common.spacing('medium');
				min-width: 30em;
				background: common.$theme-settings-fieldset-background;
				color: common.$theme-settings-fieldset-text;
				border: 1px solid common.$theme-settings-fieldset-border;
				border-radius: 0.5em;
				padding-bottom: 0.8em;

				>legend {
					font-size: 1.2em;
					font-weight: bold;
					margin-bottom: 0.5em;
					background: common.$theme-settings-fieldset-legend-background;
					color: common.$theme-settings-fieldset-legend-text;
					border: 1px solid common.$theme-settings-fieldset-border;
					border-radius: 0.5em;
					padding: 0.2em;
				}
			}

			th, td {
				text-align: center;
			}

			.input-row, .input-section {
				display: flex;
				gap: common.spacing('medium');
				justify-content: space-between;
				margin-top: 1em;

				&.input-row {
					flex-direction: row;
					align-items: center;


					img {
						vertical-align: middle;
						margin-top: -0.1em;
					}
				}

				&.input-section {
					flex-direction: column;
				}
			}

			.permission-list {
				> div {
					background: common.$theme-table-body-background;
					color: common.$theme-table-body-text;

					&:nth-child(2n) {
						background: common.$theme-table-body-altbackground;
					}
				}

			}
		}
	}

	tr.current-connection {
		background: common.$theme-settings-table-row-highlight-background;
	}

	.accentColorPresets {
		.colorPreset {
			width: 2em;
			height: 2em;
			outline-width: 0.25em;
			border-radius: 50%;

			button {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				border-width: 2px;
				// Intentionally not themed (black looks the best next to the other options)
				background: #000;
				color: #eee;
			}
		}
	}
}
