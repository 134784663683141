.roomItemDialog {
	.contentContainer {
		flex: 1;
		max-width: 100%;
		max-height: 100%;
		overflow: hidden;
		border-radius: 0 0 1em 1em;

		.contentContainerInner {
			width: 100%;
			height: 100%;
			overflow: hidden;
		}
	}

	.sectionButton {
		border: none;
		border-radius: 0;
	}

	.sectionContent {
		transition-property: height, flex-grow;
		transition-duration: 400ms;
		transition-timing-function: ease-out;
		flex-grow: 0;
		flex-basis: 0;
		overflow: auto;

		&.open {
			flex-grow: 1;
		}

		&.closed {
			height: 0;
			overflow: hidden;
		}

		.wardrobeLinkButton {
			width: 2.5em;
			height: 2.5em;
		}
	}
}
