@use '../../styles/common';

$header-height: 2em;

.wardrobe {
	display: flex;
	flex-flow: column;
	gap: 0.5em;
	height: 100%;
	min-width: fit-content;

	.safemode {
		@include common.center-flex;
		background: common.$theme-warningbox-background;
		color: common.$theme-warningbox-text;
		border: 1px solid common.$theme-separator;
		padding: 0.25em;
	}

	.wardrobeMain {
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-flow: row;

		.characterPreview, .roomPreview {
			position: relative;
			height: 100%;
			width: max(min(20em, 90vw), 33vw);
			flex-shrink: 0;

			&.disabled {
				display: none;
			}

			canvas {
				height: 100%;
				width: 100%;
				display: block;
			}

			.overlay {
				position: absolute;
				inset: 0;
				align-items: flex-start;
				pointer-events: none;

				&.solidOverlay {
					background: common.$theme-wardrobe-preview-overlay;
				}

				.iconButton {
					min-width: 2em;
					min-height: 2em;
				}

				.warning {
					@include common.center-flex;
					background: common.$theme-warningbox-background;
					color: common.$theme-warningbox-text;
					border: 1px solid common.$theme-separator;
					border-radius: 1em;
					padding: 0.25em 0.5em;
				}

				.option {
					background: common.$theme-normal-background;
					color: common.$theme-normal-text;
					border: 1px solid common.$theme-separator;
					border-radius: 1em;
					padding: 0.25em 0.5em;
				}

				.actionAttempt {
					background: common.$theme-wardrobe-preview-actionattempt-background;
					color: common.$theme-wardrobe-preview-actionattempt-text;
					border: 1px solid common.$theme-wardrobe-preview-actionattempt-border;
					border-radius: 1em;
				}
			}
		}
	}
}

.wardrobe-pane {
	display: flex;
	flex: 1;
	border: 2px solid common.$theme-separator;
	padding: 0.5em;
	background: common.$theme-wardrobe-pane-background;
	color: common.$theme-wardrobe-pane-text;
	overflow: hidden;
}

.wardrobe-ui {
	display: flex;
	flex-flow: row;
	position: relative;
	width: 100%;
	gap: 0.5em;
}

$drop-overlay-height: 1.8em;
$drop-overlay-gap: 0.3em;

.itemPreview {
	overflow: hidden;

	&.missing {
		border: 1px solid common.$theme-separator;
		@include common.center-flex;
	}

	img {
		width: 100%;
		height: 100%;
	}

	&.doNotRender img {
		filter: brightness(0%) blur(9px) !important;
	}
}

.inventoryView {
	@include common.flex(column);
	flex: 1;
	overflow: hidden;
	min-width: min(20em, 90vw);

	background: common.$theme-wardrobe-view-background;
	color: common.$theme-wardrobe-view-text;
	border: 2px solid common.$theme-separator;

	.toolbar {
		flex-shrink: 0;
		min-height: 3.5em;
		display: flex;
		flex-direction: row;
		padding: 0.25em 0.25em;
		gap: 0.3em;
		border-bottom: 1px solid common.$theme-separator;

		&.wrap {
			flex-wrap: wrap;
		}

		> span {
			flex: 1;
			display: flex;
			align-items: center;
			padding: 0.2em;
		}

		.IconButton {
			width: 3em;
			height: 3em;
		}

		.iconHeightButton {
			min-height: 3em;
		}

		.filter {
			display: flex;
			flex: 1;
			max-width: 20em;
			align-items: center;

			input[type=text] {
				padding: 0.2em;
				height: 2em;
				width: 100%;
			}
		}
	}

	.listContainer {
		display: flex;
		flex-flow: column;
		position: relative;
		overflow: hidden;
		flex: 1;
		padding: 0;
		margin: 0;
	}

	.outfit {
		width: 100%;
		border: 2px solid common.$theme-separator;

		.outfitMainButton {
			width: 100%;
			min-height: 5em;
			overflow: hidden;
			display: flex;

			border: 1px solid common.$theme-wardrobe-outfitbutton-border;
			background: common.$theme-wardrobe-outfitbutton-normal-background;
			color: common.$theme-wardrobe-outfitbutton-text;

			&:hover {
				background: common.$theme-wardrobe-outfitbutton-hover-background;
			}

			.outfitPreview {
				@include common.center-flex;
				max-height: 100%;
				width: 3.4em;
				margin: 0.2em 0;
				padding: 0;
				border: 1px solid common.$theme-wardrobe-outfitbutton-preview-border;
				overflow: hidden;
				aspect-ratio: 2 / 3;

				&.big {
					width: min(35%, 30vh);
				}

				canvas {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	&.assetPreference {
		.toolbar {
			height: 3.5em;

			.itemPreview {
				height: 100%;
				aspect-ratio: 1;
			}
		}
	}

	.overlay {
		position: absolute;
		inset: 0;
		isolation: isolate;

		z-index: 10;
		background: common.$theme-wardrobe-view-overlay;

		.centerButton {
			width: max(10em, 50%);
			height: max(8em, 40%);

			@include common.center-flex;
		}
	}

	.Scrollbar {
		flex: 1;
		overflow: auto;
		overflow-x: hidden;
	}

	.list,
	.grid {
		position: relative;
		padding: 3px;
		gap: 3px;

		&.withDropButtons {
			padding: calc($drop-overlay-height * 0.5 + 3px) 3px;
		}

		.overlayDropContainer {
			position: relative;

			min-height: $drop-overlay-gap;

			.overlayDrop {
				position: absolute;
				top: calc($drop-overlay-height * -0.5 + $drop-overlay-gap * 0.5);
				left: 3.6em;
				right: 2em;
				height: $drop-overlay-height;
				z-index: 11;

				isolation: isolate;

				@include common.center-flex;
			}
		}
	}

	.list {
		display: flex;
		flex-flow: column;

		&.reverse {
			flex-flow: column-reverse;
			justify-content: flex-end;
		}
	}

	.grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: min-content auto;
	}

	@media (min-width: 1536px) {
		.grid {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	.description {
		white-space: pre-wrap;
		overflow-wrap: break-word;
	}

	>.fieldset-toggle {
		margin-top: 1em;
	}
}

.inventoryViewItem {
	border: 1px solid common.$theme-wardrobe-actionbutton-border;
	display: flex;
	position: relative;

	>.colorRibbon {
		position: absolute;
		display: inline-block;
		top: 0;
		left: 0;
		width: 0.9em;
		height: 0.9em;
		clip-path: polygon(0 0, 100% 0, 0 100%);
		background: common.$theme-wardrobe-itementry-ribbon-border;

		.colorRibbonInner {
			position: absolute;
			top: 0;
			left: 0;
			right: 1px;
			bottom: 1px;
			clip-path: polygon(0 0, 100% 0, 0 100%);
			box-shadow: common.$theme-wardrobe-itementry-ribbon-shadow;
		}
	}

	&.listMode {
		min-height: 3em;
		align-items: center;
		gap: 0.5em;

		&.small {
			min-height: 2em;

			.itemPreview {
				height: 1.5em;
				width: 1.5em;
				margin: 0.25em;

				&.image {
					height: 2em;
					width: 2em;
					margin: 0;
				}
			}
		}

		.itemName {
			margin: 3px;
			flex: 1;
		}

		.itemPreview {
			height: 1.5em;
			width: 1.5em;
			margin: 0.75em;

			&.image {
				height: 3em;
				width: 3em;
				margin: 0;
			}
		}

		.quickActions {
			margin: 3px 3px 3px auto;
		}

		&.sidePadding {
			padding: 0 0.5em;
		}
	}

	&.gridMode {
		padding: 0.5em;
		flex-direction: column;
		row-gap: 0.5em;

		.itemPreview {
			width: 100%;
			aspect-ratio: 1;
		}

		.itemName {
			@include common.center-flex;
			text-align: center;
			min-height: 2em;
			width: 100%;
		}

		.quickActions {
			flex-flow: wrap;
			margin: auto;
		}

		&::after {
			top: 0.3em;
		}
	}

	&::after { // Used for displaying asset preferences
		position: absolute;
		right: 0.3em;
		font-size: 1.5em;
		line-height: 1em;
		width: 1em;
		height: 1em;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-shadow: 0 0 0.05em common.$theme-wardrobe-assetpreferences-shadow, 0 0 0.05em common.$theme-wardrobe-assetpreferences-shadow, 0 0 0.05em common.$theme-wardrobe-assetpreferences-shadow, 0 0 0.05em common.$theme-wardrobe-assetpreferences-shadow;
	}

	&.pref-favorite {
		.itemName {
			font-weight: bold;
		}

		&::after {
			content: '★';
			color: common.$theme-wardrobe-favorite-color;
		}
	}

	&.pref-maybe {
		.itemName {
			font-style: italic;
		}

		&::after {
			content: '?';
			color: common.$theme-wardrobe-maybe-color;
		}
	}

	&.pref-prevent {
		.itemPreview.image>img {
			filter: grayscale(100%);
		}

		.itemName {
			text-decoration: line-through;
			font-style: italic;
		}

		&::after {
			content: '✕';
			color: common.$theme-wardrobe-prevent-color;
		}
	}

	&.pref-doNotRender {
		.itemPreview.image>img {
			filter: brightness(0%) blur(9px);
		}

		.itemName {
			text-decoration: line-through;
			font-style: italic;
		}

		&::after {
			content: '✕';
			color: common.$theme-wardrobe-doNotRender-color;
			background: common.$theme-wardrobe-prevent-color;
		}
	}

	.quickActions {
		display: flex;
		gap: 0.2em;
		margin-left: auto;

		.wardrobeActionButton {
			height: 2.2em;
			width: 2.2em;
		}
	}

	.overlayDrop {
		@include common.center-flex;
		isolation: isolate;
		position: absolute;
		top: 0.2em;
		bottom: 0.2em;
		left: 2.6em;
		right: 1em;
		z-index: 11;
	}
}

.itemName {
	&.custom {
		font-style: italic;
	}
}

.wardrobeActionButton {
	position: relative;
	@include common.center-flex;
	border: 1px solid common.$theme-wardrobe-actionbutton-border;
	border-radius: 0.2em;
	z-index: 1;

	&:not(.slim) {
		min-height: 2.5em;
		min-width: 2.5em;
	}

	&.IconButton {
		padding: 0.15em;
		width: 3.3em;
		height: 3.3em;

		img {
			width: 100%;
			height: 100%;
			user-select: none;
			pointer-events: none;
			object-fit: contain;
		}
	}

	&.standardButtonSize {
		padding: 0.8em 1.2em;
	}
}

.wardrobeActionButton, .inventoryViewItem {
	background: common.$theme-wardrobe-actionbutton-normal-background;
	color: common.$theme-wardrobe-actionbutton-normal-text;

	&.selected {
		background: common.$theme-wardrobe-actionbutton-selected-background;
		color: common.$theme-wardrobe-actionbutton-selected-text;
	}

	&.requiresAttempt:not(:disabled):not(.selected) {
		background: common.$theme-wardrobe-actionbutton-requiresAttempt-background;
		color: common.$theme-wardrobe-actionbutton-requiresAttempt-text;

		&:hover {
			background: common.$theme-wardrobe-actionbutton-requiresAttempt-hover-background;
			color: common.$theme-wardrobe-actionbutton-requiresAttempt-hover-text;
		}
	}

	&.pendingAttempt:not(.progressDone) {
		background: common.$theme-wardrobe-actionbutton-progress-pending;
		--progress: 0%;
		background: linear-gradient(
			to bottom,
			common.$theme-wardrobe-actionbutton-progress-done var(--progress),
			common.$theme-wardrobe-actionbutton-progress-pending var(--progress),
		);
		// --progress: 0deg;
		// background: conic-gradient(
		// 	$theme-wardrobe-actionbutton-progress-done var(--progress),
		// 	$theme-wardrobe-actionbutton-progress-pending var(--progress),
		// );
	}

	:not(:disabled) {
		cursor: pointer;
	}

	&.allowed:not(:disabled) {
		&:hover:not(.pendingAttempt:not(.progressDone)) {
			background: common.$theme-wardrobe-actionbutton-hover-background;
			color: common.$theme-wardrobe-actionbutton-hover-text;

			&.inventoryViewItem {
				background: common.$theme-wardrobe-itementry-hover-background;
				color: common.$theme-wardrobe-itementry-hover-text;
			}

			&.selected {
				background: common.$theme-wardrobe-actionbutton-selectedhover-background;
				color: common.$theme-wardrobe-actionbutton-selectedhover-text;
			}
		}
	}

	&.blocked:not(.selected) {
		background: common.$theme-wardrobe-actionbutton-blocked-background;
		color: common.$theme-wardrobe-actionbutton-blocked-text;
	}

	&.pending:not(.selected) {
		background: common.$theme-wardrobe-actionbutton-pending-background;
		color: common.$theme-wardrobe-actionbutton-pending-text;
	}

	&.promptRequired:not(.selected) {
		background: common.$theme-wardrobe-actionbutton-promptRequired-background;
		color: common.$theme-wardrobe-actionbutton-promptRequired-text;
	}

	&:disabled {
		background: common.$theme-wardrobe-actionbutton-disabled-background;
		color: common.$theme-wardrobe-actionbutton-disabled-text;

		&.selected {
			background: common.$theme-wardrobe-actionbutton-selected-background;
			color: common.$theme-wardrobe-actionbutton-selected-text;
		}
	}

	&:focus-visible {
		border-color: common.$theme-wardrobe-actionbutton-visiblefocus-border;
	}
}

.wardrobeColorRow {
	display: flex;
	align-items: center;
	gap: 0.5em;
	margin-bottom: 0.5em;
}

.bone-ui {
	overflow: auto;
	padding: 0.5em;
	display: flex;
	flex-flow: column;
	gap: 0.5em;

	>h3 {
		margin-left: 2rem;
	}

	>h4 {
		margin: 0 0 0 0.5em;
	}

	.requestedPoseIndicatorText {
		padding: 0 0.5em;
	}

	.pose-row {
		margin: 0 1em;

		.pose {
			&.pose-unavailable {
				opacity: 60%;
			}
		}
	}

	.armPositioningTable {
		margin: 0.5em;

		&, td {
			border: 1px solid common.$theme-separator;
			border-collapse: collapse;
			padding: 0.25em;
			text-align: center;
		}
	}

	.bone-rotation {
		display: flex;
		flex-flow: row;
		width: 100%;
		gap: 0 0.5rem;

		>input[type='range'] {
			width: 100%;
			border-radius: 0.2em;
			padding: 0;
		}

		>input[type='number'] {
			text-align: right;
			width: 5rem;
			border: 0.1em solid;
			border-radius: 0.2em;
		}
	}
}

.action-warning, .attribute-description {
	background: common.$theme-hoverdialog-background;
	color: common.$theme-hoverdialog-text;
	border: 2px solid common.$theme-hoverdialog-border;
	padding: 1em;
	border-radius: 1em;
	line-height: 1.5em;
}

.attribute-description {
	text-align: center;
}

.WardrobeLockPassword {
	margin: 1em 0;
}

.WardrobeInputRow {
	margin-left: 0.5em;
	min-height: 1.6em;

	label {
		flex: 1 1;
		margin: auto 0;
	}

	.error {
		width: 100%;
		color: common.$theme-wardrobe-errormessage-text;
		text-align: right;
	}
}

.positioning-input {
	width: 8em;
}

.input-row {
	label, span {
		margin: auto 0;
	}
}

.permissionPrompt {
	i {
		max-width: 26em;
	}

	.input-row {
		display: flex;
		gap: 0.5em;
		justify-content: space-between;
		flex-direction: row;
		align-items: center;

		label, span {
			margin: auto 0;
			display: flex;
		}
	}
}

.wardrobeItemCustomizationView {
	.name {
		display: inline-block;
		font-size: 1em;
		padding: 0.5em;
		flex-grow: 1;

		&:not(:is(input)) {
			border: 1px solid common.$theme-wardrobe-itemname-border;
			background: common.$theme-wardrobe-itemname-background;
			color: common.$theme-wardrobe-itemname-text;
		}
	}

	.description {
		padding: 0.5em;
		white-space: pre-wrap;
		word-wrap: break-word;
		word-break: break-word;
		min-height: 12em;

		&:not(:is(textarea)) {
			border: 1px solid common.$theme-wardrobe-itemdescription-border;
			background: common.$theme-wardrobe-itemdescription-background;
			color: common.$theme-wardrobe-itemdescription-text;
		}
	}

	.error {
		color: common.$theme-wardrobe-errormessage-text;
	}

	input {
		flex-grow: 1;
	}
}

.wardrobeProblemMessage {
	text-align: center;
}
