@use '../../../styles/common';

.chatArea {
	display: flex;
	flex-flow: column;
	flex: 1;
	background: common.$theme-chat-background;
	color: common.$theme-chat-text;
	overflow: hidden;

	> .messagesArea {
		position: relative;
		flex: 1;
		overflow: hidden;
		user-select: text;

		.messagesContainer {
			padding: 0 0 0.25em 0;
		}

		.message {
			padding: 0.1em 0.1em 0.1em 0.25em;
			white-space: pre-wrap;
			overflow-wrap: break-word;

			&:not(:hover):not(.serverMessage):not(.action) {
				// Prevent Zalgo text from getting weird (but still show it if the message is hovered)
				overflow: hidden;
				overflow: clip;
				overflow-clip-margin: 0.05em;
			}

			&.action, &.serverMessage {
				.itemLink {
					color: common.$theme-chat-serverMessage-text;
					text-decoration: none;

					&.hasCustomName, &.hasDescription {
						&::before {
							content: '[';
						}
						&::after {
							content: ']';
						}
					}

					&.hasDescription {
						color: common.$theme-chat-serverMessage-textlink;
					}

					&:hover {
						color: common.$theme-chat-serverMessage-textlink-hover;
					}
				}
			}

			&.serverMessage {
				border-left: 5px solid common.$theme-chat-serverMessage-notch;
				background: common.$theme-chat-serverMessage-background;
				color: common.$theme-chat-serverMessage-text;
				margin: 0.4em;
				padding: 0.2em;

				&.foldable:hover {
					background: common.$theme-chat-serverMessage-foldable-hover-background;
					cursor: pointer;
				}
			}

			&.emote .name {
				display: none;
			}

			&.private {
				opacity: 0.7;
			}

			> .info {
				float: right;
				color: common.$theme-chat-messageinfo-text;
				font-style: italic;
				font-size: 0.6em;
				margin-right: 0.2em;
			}

			> .repetitionCount {
				padding: 0 0.25em;
				border-radius: 1em;

				font-family: common.$theme-font-family;
				font-feature-settings: common.$theme-font-features-tabular;

				background: common.$theme-chat-messagerepetitions-background;
				border: 1px solid common.$theme-separator;
				outline: 0px solid common.$theme-accent-color;

				display: inline-block;

				&.highlightChange {
					animation: 0.8s chat-highlight-repetition-count ease-in-out;
				}
			}

			a {
				text-decoration: underline;
			}

			&.editing {
				background: common.$theme-chat-editedMessage-background;
				padding: 0.2em;
				margin: 0 0.2em;
				border-left: 2px solid common.$theme-chat-editedMessage-notch;
			}
		}
	}

	textarea {
		height: min(8.5em, 35%);
		resize: none;
		margin: 0;
		background: common.$theme-chat-input-normal-background;
		color: common.$theme-chat-input-text;
		border: none;
		outline: none;

		&:focus {
			background: common.$theme-chat-input-focused-background;
		}

		&::placeholder {
			color: common.$theme-chat-input-placeholder-text;
			opacity: 1; /* Firefox */
		}
	}
}

@keyframes chat-highlight-repetition-count {
	0%, 100% {
		transform: none;
		outline-width: 0;
	}
	10%, 90% {
		transform: none;
		outline-width: 2px;
	}
	50% {
		transform: scale(120%);
		outline-width: 2px;
	}
}

.typing-indicator, .input-modifiers, .unread-messages-indicator {
	@include common.flex(row, wrap);
	align-items: center;

	background: common.$theme-chat-indicator-background;
	color: common.$theme-chat-indicator-text;
	padding-left: 0.5em;
	gap: 0.5em;
}

.typing-indicator, .unread-messages-indicator {
	min-height: 1.5em;
	overflow: hidden;
	white-space: nowrap;
}

.typing-indicator {
	padding-right: 0.5em;
	cursor: pointer;

	&:hover {
		background: common.$theme-chat-indicator-interactive-hover-background;
	}

	img {
		height: 1em;
		filter: brightness(0) saturate(100%) invert(100%);
	}
}

.unread-messages-indicator:hover {
	background: common.$theme-chat-indicator-interactive-hover-background;
	cursor: pointer;
}

.autocomplete-hint {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	max-height: calc(100% - 0.4em);
	overflow-y: scroll;
	margin: 0.2em;
	font-size: medium;

	@include common.flex(column);
	background: common.$theme-autocomplete-hint-background;
	color: common.$theme-autocomplete-hint-text;
	padding: 0.5em;
	gap: 0.1em;

	hr {
		width: 100%;
	}

	span {
		cursor: pointer;

		&:hover, &.selected {
			background: common.$theme-autocomplete-hint-entry-hover-background;
		}
	}
}
